<template>
  <div class="writelogistics">
    <title-top>退款详情</title-top>
    <div class="row align-center font32 f-white bg-F62341 h176">
      <span class="ml25">请退货并填写物流信息</span>
    </div>
    <div class="row align-center bg-white h80">
      <p class="font28 f-1A1A1A ml25">您已成功发起退款申请，请耐心等待商家处理。</p>
    </div>
    <div class="line"></div>
    <div class="row align-center bg-white">
      <img class="imgsize32 ml25 mr16" src="~images/zyimg/shadd.png" alt />
      <div class="font28 f-333333 mt20 mb20">
        <span class="mr25">收货人：{{logistics.receiver_name}}</span>
        <p class="mt20 mr25">地址：{{logistics.return_address}}</p>
      </div>
    </div>
    <div class="line"></div>
    <div class="column bg-white mb20">
      <span class="font28 f-1A1A1A ml25 mt20">退款说明：</span>
      <p class="font24 f-666666 ml25 mr25 mt20">{{res.remark}}</p>
      <div class="row flex-end font28 f-1A1A1A">
        <span class="row flex-center br8 hd-border mt20 mb20" @click="onCancle">撤销申请</span>
        <div class="column flex-center br8 hd-border font28 f-1A1A1A ml16 mr25 mt20 mb20" @click="onWl">
          <span>我已寄出</span>
          <span class="font24 f-333333">点击填写物流单号</span>
        </div>
      </div>
    </div>
    <div class="column bg-white font28 f-1A1A1A mb20">
      <span class="ml25 mt25">退款信息</span>
      <div class="row between align-center bg-white" v-for="(item,i) in res.order.items" :key="i">
        <img class="imgsize176 br4 ml25 mr16 mt25 mb25" :src="item.image_url" alt />
        <div class="flex1 column font24 f-333333 mt25 mb25">
          <p class="mr25 ellipsis">{{item.product_name}}</p>
          <div class="row between f-999999 mt16 align-center">
            <span>{{item.specification_values | spec}}</span>
            <span class="ml25 mr25">×{{item.quantity}}</span>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="font24 f-999999 bg-white">
        <p class="mt25 ml25 mb16">退款原因：{{res.reason}}</p>
        <p class="ml25 mb16">退款金额：￥{{res.order.paid_amount}}</p>
        <p class="ml25 mb16">申请时间：{{res.created_at | formatDate}}</p>
        <p class="ml25 mb16">退款编号：{{res.number}}</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { formatDate } from "common/util";
import {cancelApply,refundsDetail} from 'network/igou2'
export default {
  name: "WriteLogistics",
  data() {
    return {
      id:this.$route.query.id,
      res:{order:{items:[]}},
      logistics:{}
    };
  },
  mounted() {
    this._refundsDetail();
  },
  methods:{
    onWl(){
      this.$router.push({
        path:'/zywriterefund',
        query:{id:this.res.id}
      })
    },
    onCancle() {
      this._cancelApply();
    },
    _cancelApply() {
      cancelApply(this.id).then(res => {
        console.log(res);
        // location.reload();
        this.$router.back();
      });
    },
    _refundsDetail(){
      refundsDetail(this.id).then(res=>{
        console.log(res)
        this.res = res.data;
        this.logistics = res.data.logistics?res.data.logistics:{}
      })
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    spec(specification_values) {
      let str = '';
      specification_values.forEach(item => {
        str += item.content+","
      });
      if(str){
        str.substring(0,str.length-1);
      }
      return str;
    },
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
.hd-border {
  width: 2.986667rem;
  height: 1.52rem;
  border: 1px solid #999999;
}
</style>
